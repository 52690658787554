import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import FooterMenu from "./footer-menu";
import '../assets/scss/regions/footer.scss';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
  const { wp } = useStaticQuery(graphql`
    {
      wp {
        themeGeneralSettings {
          settings {
            phoneNumberHeader
            phoneNumberFooter
            address
            text
            copyright
            logo {
              remoteFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const settings = wp.themeGeneralSettings.settings;

  return (
    <footer className="footer">
      <Container fluid="xl">
        <Row>
          <Col>
            <div className="footer-top">
              <div className="group first">
                <FooterMenu />
              </div>
              <div className="group second">
                {settings.text && <span>{settings.text}</span>}

                <Img fluid={settings.logo.remoteFile.childImageSharp.fluid}
                sizes={{ ...settings.logo.remoteFile.childImageSharp.fluid}}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="footer-bottom">
              {settings.address && <p>{settings.address}</p>}
              {settings.phoneNumberFooter &&
                <a href={"tel:" + settings.phoneNumberFooter}>{settings.phoneNumberFooter}</a>
              }
              {settings.copyright && <p>© {new Date().getFullYear()} {settings.copyright}</p>}
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer