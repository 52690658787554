import React from 'react';
import { bool, func } from 'prop-types';


const Burger = ({ open, setOpen }) => {
  return (
    <button className="burger" open={open} onClick={() => setOpen(!open)}>
      <span className="bar"></span>
    </button>
  )
}

Burger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default Burger;
