import React, { useState } from "react"
// import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby"
import Logo from '../assets/svg/logo.inline.svg'
import Menu from "./menu"
import MobileMenu from "./mobile-menu"
import Burger from './burger';
import { Container } from 'react-bootstrap';
import "../assets/scss/regions/header.scss";
import '../assets/scss/regions/hamburger.scss';





const Header = ({isHeroVisible}) => {

  const [open, setOpen] = useState(false);
  
  // const { wp } = useStaticQuery(graphql`
  //   {
  //     wp {
  //       themeGeneralSettings {
  //         settings {
  //           phoneNumberHeader
  //         }
  //       }
  //     }
  //   }
  // `)

  return (

    <header className={`site-header ${isHeroVisible ? 'default' : ''}`}>
      <Container fluid="xl">
        <nav className="navbar">
          <div className="logo">
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <Menu />
          {/* {wp.themeGeneralSettings.settings.phoneNumberHeader && <a href="#">{wp.themeGeneralSettings.settings.phoneNumberHeader}</a>} */}
          <Burger open={open} setOpen={setOpen} />
          <MobileMenu open={open} setOpen={setOpen} />
        </nav>
      </Container>
    </header>

    )
  }

export default Header
