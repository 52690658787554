import React from "react"
import Helmet from 'react-helmet'
import Header from "./header"
import Footer from "./footer"
import favicon from '../assets/images/favicon.svg'
import "../assets/style.scss"

const Layout = ({ children, isHeroVisible }) => {

  return (
    <div>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <Header isHeroVisible={isHeroVisible}/>
      {children}
      <Footer />
    </div>
  );
};

export default Layout
