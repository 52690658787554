import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import "../assets/scss/regions/mobile-menu.scss"
import { normalizePath } from "../utils/get-url-path"
import { bool } from 'prop-types';

export default function MobileMenu ({ open }) {
  const { wpMenu } = useStaticQuery(graphql`
    {
      wpMenu(slug: { eq: "mobile" }) {
        name
        menuItems {
          nodes {
            url
            label
          }
        }
      }
    }
  `)

  return !!wpMenu && !!wpMenu.menuItems && !!wpMenu.menuItems.nodes ? (
    <ul className="mobile-menu" open={open}>
    {wpMenu.menuItems.nodes.map((menuItem, i) => {
      const path = menuItem?.connectedObject?.uri ?? menuItem.url

      return (
        <li>
          <Link to={normalizePath(path)} key={i + menuItem.url}>
              {menuItem.label}
          </Link>
        </li>
      )
    })}
    </ul>
  ) : null
}

MobileMenu.propTypes = {
  open: bool.isRequired,
}


