import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
// import { Menu, Button, Grid, Box } from "@chakra-ui/core"
import { normalizePath } from "../utils/get-url-path"

export default () => {
  const { wpMenu } = useStaticQuery(graphql`
    {
      wpMenu(slug: { eq: "footer" }) {
        name
        menuItems {
          nodes {
            url
            label
          }
        }
      }
    }
  `)

  return !!wpMenu && !!wpMenu.menuItems && !!wpMenu.menuItems.nodes ? (

        <ul className="footer-menu">
          {wpMenu.menuItems.nodes.map((menuItem, i) => {
            const path = menuItem?.connectedObject?.uri ?? menuItem.url

            return (
              <li>
                 <Link to={normalizePath(path)} key={i + menuItem.url}>
                  {menuItem.label}
                </Link>
              </li>
            )

          })}
        </ul>

  ) : null
}
